






























































/* eslint-disable */
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import { CMS } from "@/models";
import { mixins } from 'vue-class-component';
import { Translatable } from '@/mixins';

@Component
export default class MapLibrePopupFix extends mixins(Translatable) {

    
    @Prop({default: false}) visible!: boolean;

    @Prop({}) eventLocation!: CMS.EventLocation;


    @Watch('visible')
    onVisibilityChange(val: boolean, oldVal: boolean){
        console.log("-------------------------vis change", val);
    }

    get cover(): string {
        if(this.eventLocation && this.eventLocation.cover){
            return this.eventLocation.cover.sizes.thumbs.path;
        }else{
            return "https://via.placeholder.com/80/000000/000000";
        }
    }

    broadcastCloseRequest(){
        console.log("sending close popup request");
        this.$emit("closeRequest", null);
    }

    onSelectionRequest(){
        console.log("on selection request");
        this.$emit("selectionRequest", {});
    }

    beforeDestroy() {
        console.log("removing popup");
    }


}
