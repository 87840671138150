








































































/* eslint-disable */

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Toggle extends Vue {
  @Prop({default: false}) readonly disabled!:boolean;

  @Prop({default:true}) readonly value!:boolean;

  toggle(e){
      const toggled = e.target.checked;
      this.$emit('change', { value: toggled });
  }

}
